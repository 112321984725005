form {
    width: 100%;
    max-width: 80rem;
    margin: 0 auto;
    //text-align: center
    * {
        color: white; }
    p, input, span, label {
        font-size: $normal; }
    .checkbox--container {
        margin-bottom: 2rem;
        text-align: left;
        @include tablet-min {
            display: flex;
            flex-wrap: wrap; } }
    .checkbox--group {
        flex: 1 0 40%;
        margin-right: 2rem;
        margin-bottom: 1rem;
        &:nth-child(2n) {
            margin-right: 0; }
        label {
            display: flex;
            flex-wrap: nowrap;
            position: relative;
            &:before {
                box-sizing: border-box;
                content: "";
                position: relative;
                display: block;
                width: 1.5rem;
                min-width: 1.5rem;
                height: 1.5rem;
                background: white;
                border: 3px solid white;
                margin-right: 1rem;
                border-radius: 50%;
                top: 3px; } }
        input {
            display: none;
            &:checked {
                ~label {
                    &:before {
                        background: $green; } } } } }
    .input--group {
        width: 100%;
        position: relative;
        margin-bottom: 2rem;
        label {
            position: absolute;
            bottom: 1rem;
            left: 0;
            transition: all 200ms ease;
            span {
                transition: inherit; } }
        input {
            display: block;
            width: 100%;
            border: none;
            border-bottom: 2px solid white;
            background: rgba(white, 0.0)!important;
            padding: 1rem 0;
            padding-top: 3rem;
            color: white!important;

            &:required {
                border: none;
                border-bottom: 2px solid white;
                ~label {
                    transform: translateY(0);
                    opacity: 1;
                    span {
                        font-size: $normal; } } }
            &:focus, &:valid {
                ~label {
                    transform: translateY(-3rem);
                    opacity: 0.5;
                    span {
                        font-size: $small; } } }
            &.touched {
                &:invalid {
                    border: none;
                    border-bottom: 2px solid #ffcb00;
                    ~label {
                        transform: translateY(-3rem);
                        opacity: 0.5;
                        span {
                            font-size: $small; } } } } } }
    .main--button {
        background: white;
        color: $green!important;
        -webkit-text-fill-color: $green; } }


.result {
    min-height: 80vh;
    display: flex;
    align-items: center;
    * {
        color: white; } }
