.main--button {
    // all: unset
    // appearance: none
    border: none;
    outline: none;
    box-sizing: border-box;
    background: $gradient;
    color: white!important;
    transition: all 300ms ease;
    padding: 1rem 4rem;
    margin: 2rem auto;
    border-radius: 20rem;
    font-size: $normal;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    -webkit-text-fill-color: white;
    &:hover {
        padding: 1rem 5rem; }
    &.solid {
        background: $blue; }
    &.cookie--btn {
        margin: 2rem 0 0 0!important;
        padding: 1rem 2rem;
        &:hover {
            padding: 1rem 2rem;
            opacity: 0.7; } } }
.grey--button {
    all: unset;
    appearance: none;
    box-sizing: border-box;
    background: $grey;
    color: white!important;
    -webkit-text-fill-color: white;
    transition: all 300ms ease;
    padding: 1rem 4rem;
    margin: 0!important;
    border-radius: 20rem;
    font-size: $normal;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    &:hover {
        opacity: 0.7; } }

.button {
    text-decoration: underline;
    color: $blue!important;
    font-weight: bold; }
