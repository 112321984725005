.grid--container {
    padding-top: 4rem;
    background: $gradient;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 3rem;
        height: 3rem;
        display: block;
        background: white; } }
.tools__content {
    display: flex;
    flex-wrap: wrap;
    .desktop {
        display: none;
        @include desktop-min {
            display: block; } }
    .mobile {
        @include desktop-min {
            display: none; } }
    .grid__child {
        cursor: pointer;
        margin: 0 4rem 4rem 0;
        text-align: center;
        h3 {
            margin-top: 2rem;
            font-weight: bold;
 }            // text-transform: lowercase
        h3, p {
            color: white; }
        &.tools__desktop {
            position: relative;
            height: 100%;
            margin-bottom: 8rem;
            margin-right: 0;
            @include tablet-min {
                margin-right: 4rem;
                width: calc(100% / 2 - 2rem);
                &:nth-of-type(2n) {
                    margin-right: 0; } }
            @include desktop-min {
                width: calc(100% / 3 - 2.7rem);
                &:nth-of-type(2n) {
                    margin-right: 4rem; }
                &:nth-of-type(3n) {
                    margin-right: 0; } }
            .screen__content {
                background: white;
                position: absolute;
                left: 3%;
                right: 3%;
                top: 0;
                transform: translateY(5%);
                height: 0;
                padding-bottom: 52%;
                overflow: hidden;
                img {
                    position: relative;
                    z-index: 1; } } } } }
