.content {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 4rem 2rem;
    position: relative; }
.centered {
    margin: 0 auto;
    width: 100%;
    max-width: 80rem;
    padding: 5rem 2rem;
    text-align: left;
    @include tablet-min {
        text-align: center; } }


