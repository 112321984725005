// Media Querys
@mixin tablet-only {
    @media (min-width: $tablet) and (max-width: $tablet-max) {
        @content; } }
@mixin tablet-min {
    @media (min-width: $tablet) {
        @content; } }
@mixin desktop-only {
    @media (min-width: $desktop) and (max-width: $desktop-max) {
        @content; } }
@mixin desktop-min {
    @media (min-width: $desktop) {
        @content; } }
@mixin large {
    @media (min-width: $desktop-large) {
        @content; } }
@mixin full {
    @media (min-width: $full) {
        @content; } }


@mixin retina {
    @media (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        @content; } }
