nav {
    position: relative;
    background: white;
    text-align: center;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;
    box-shadow: 0 0 1rem rgba(black, 0.15);

    img {
        width: 25rem;
        margin: 0 auto; }
    .sticky {
        display: none;
        &--button {
            @extend .main--button;
            margin: 0;
            font-size: $small;
            display: flex;
            align-items: center;
            width: 4rem;
            height: 4rem;
            padding: 0;
            @media (min-width: 500px) {
                width: auto;
                height: auto;
                padding: 1rem 4rem; }
            &:hover {
                padding: 0;
                @media (min-width: 500px) {
                    padding: 1rem 5rem; } }


            img {
                width: 1.6rem;
                @media (min-width: 500px) {
                    margin-left: 1rem; } }
            span {
                color: white;
                font-weight: 500;
                display: none;
                @media (min-width: 500px) {
                    display: block; } } } } }

.home {
    nav {
        .sticky {
            display: block; } } }

