#modal {
    position: fixed;
    z-index: 20;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(black, 0.8);
    padding: 2rem;
    color: black;
    display: flex;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: block; }
    align-items: center;
    justify-content: center;
    // border-radius: 0.4rem
    overflow-y: auto;
    @media (max-height: 640px) {
        display: block; }
    p, span, * {
        font-size: $small;
        color: $dgrey; }

    strong {
        display: block;
        margin-bottom: 2rem; }

    em {
        font-size: $normal;
        color: $blue;
        font-style: normal;
        text-align: left!important; }
    h3 {
        font-size: $large;
        // text-transform: lowercase
        color: $dgrey; }
    span.close {
        cursor: pointer;
        position: absolute;
        left: 2rem;
        top: 1rem;
        z-index: 2;
        color: $blue;
        font-size: $large; }
    img {
        max-width: 100%;
        width: 100%;
        border: 1px solid $dgrey; }
    >.flex {
        padding: 4rem 2rem;
        @include tablet-min {
            padding: 4rem 3rem; }
        padding-top: 4rem;
        height: auto;
        position: relative;
        width: 50rem;
        max-width: 100%;
        flex-wrap: wrap;
        background: white;
        border-radius: 0.8rem;
        margin: 0 auto;
        box-shadow: 0 0 1rem rgba(black, 0.3);
        text-align: center;
        overflow: hidden;
        // border-top: 1px solid $grey
        // border-left: 1px solid desaturate($blue, 50%)
        // border-bottom: 1px solid $blue
        // border-right: 1px solid $blue

        .col-1 {
            min-width: 24rem;
            padding: 2rem;
            text-align: left; }
        .gradient {
            background: $gradient;
 } } }            // height: 50%

