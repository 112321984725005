* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  color: $dgrey; }

html, body {
  height: 100%;
  font-size: 10px;
  line-height: 1.5; }
img {
    display: block;
    max-width: 100%; }
body {
    padding-top: 8rem; }


.gradient {
    background: $gradient; }
