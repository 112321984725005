h1, h2 {
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 2rem;
    font-size: $large;
    @include tablet-min {
        font-size: $huge; } }
h3 {
    font-weight: 500;
    margin-bottom: 1rem;
    font-size: $normal;
    @include tablet-min {
        font-size: $medium; } }
p, li, a, strong {
    font-size: $normal; }
a {
    color: inherit; }

strong {
    font-size: inherit; }
