.twododigital--cookie {
    position: fixed;
    bottom: 2rem;
    width: 300px;
    right: 2rem;
    background: white;
    box-shadow: 0 0 1rem rgba(black, 0.15);
    border-radius: 4px;
    padding: 2rem;
    text-align: center;
    p {
        margin-bottom: 2rem; }
    .twododigital--cookie-buttons {
        button {
            margin: 1rem;
            width: 100%; } } }
