
footer {
    padding: 2rem;
    background: white;
    text-align: center;
    position: relative;
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 5px;
        background: $gradient; }
    p {
        margin-bottom: 2rem; }
    span {
        font-size: $normal; }
    a {
        transition: color 300ms ease;
        &:hover {
            color: $green; } }

    @include desktop-min {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
            margin-bottom: 0; } } }
